<template>

  <div class="library">
    <b-container>
    <b-nav class="library__nav">
      <b-nav-item :to="{name: 'typography'}">Typography</b-nav-item>
      <b-nav-item :to="{name: 'components'}">Components</b-nav-item>
      <b-nav-item :to="{name: 'icons'}">Icons</b-nav-item>
    </b-nav>

    <div class="library__content">
      <router-view></router-view>
    </div>
    </b-container>

  </div>
</template>

<script>


export default {
  name: 'library',

  components: {
  },

  props: {},

  data() {
    return {
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
  }
}
</script>

<style lang="scss">

  body {
    height: 100%;
  }

</style>